
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexko5D5C1RIAMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/pages/about/index.vue?macro=true";
import { default as indexZyOINLZshOMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/pages/contact/index.vue?macro=true";
import { default as indexe1lBY7jdNHMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/pages/index.vue?macro=true";
import { default as _91slug_936XIKKq1RbtMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/pages/league/[slug].vue?macro=true";
import { default as _91slug_93T02BLS8gz4Meta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/pages/match/[slug].vue?macro=true";
import { default as indexHI21wusNnxMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/pages/privacy-policy/index.vue?macro=true";
export default [
  {
    name: "about___es-mx",
    path: "/sobre-nosotros",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/pages/about/index.vue")
  },
  {
    name: "contact___es-mx",
    path: "/contacto",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/pages/contact/index.vue")
  },
  {
    name: "index___es-mx",
    path: "/",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/pages/index.vue")
  },
  {
    name: "league-slug___es-mx",
    path: "/liga/:slug()",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/pages/league/[slug].vue")
  },
  {
    name: "match-slug___es-mx",
    path: "/noticias/:slug()",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/pages/match/[slug].vue")
  },
  {
    name: "privacy-policy___es-mx",
    path: "/politica-de-privacidad",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/pages/privacy-policy/index.vue")
  }
]