import revive_payload_client_4sVQNw7RlN from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _0_polyfills_client_zsTOIvdhBg from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/plugins/0.polyfills.client.js";
import _0_sentry_client_qc5al9Mks6 from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/plugins/0.sentry.client.js";
import axios_sVCuMR6hEC from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/plugins/axios.js";
import cache_client_raoCUGGyPw from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/plugins/cache.client.js";
import cacheFetch_IdpsBDcdcT from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/plugins/cacheFetch.js";
import datefns_isFzIzek74 from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/plugins/datefns.js";
import geo_mdQkkZbcDN from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/plugins/geo.js";
import gtm_client_KEcT5dOoKs from "/home/cleavr/futbolhoyenvivo.mx/releases/20250122090326251/plugins/gtm.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  _0_polyfills_client_zsTOIvdhBg,
  _0_sentry_client_qc5al9Mks6,
  axios_sVCuMR6hEC,
  cache_client_raoCUGGyPw,
  cacheFetch_IdpsBDcdcT,
  datefns_isFzIzek74,
  geo_mdQkkZbcDN,
  gtm_client_KEcT5dOoKs
]