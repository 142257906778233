export const FEED_TYPE_LEAD = 'lead';
export const FEED_TYPE_HOW_TO_WATCH = 'how_to_watch';
export const FEED_TYPE_TIME_TABLE = 'time_table';
export const FEED_TYPE_PREDICTIONS = 'predictions';
export const FEED_TYPE_HEAD_TO_HEAD = 'head_to_head';
// @deprecated
export const FEED_TYPE_FORM = 'form';
export const FEED_TYPE_FORM_HOME = 'form_home';
export const FEED_TYPE_FORM_AWAY = 'form_away';
export const FEED_TYPE_CLASSIFICATION = 'classification';

export const MATCH_CONTENT_TYPE_H1 = 'h1';
export const MATCH_CONTENT_TYPE_INTRO = 'intro';
export const MATCH_CONTENT_TYPE_LEAD = 'lead';
export const MATCH_CONTENT_TYPE_INFO_TITLE = 'info_title';
export const MATCH_CONTENT_TYPE_INFO_CONTENT = 'info_content';
export const MATCH_CONTENT_TYPE_TRANSMISSION_TITLE = 'transmission_title';
export const MATCH_CONTENT_TYPE_TRANSMISSION_CONTENT = 'transmission_content';
export const MATCH_CONTENT_TYPE_PREDICTIONS_TITLE = 'predictions_title';
export const MATCH_CONTENT_TYPE_PREDICTIONS_CONTENT = 'predictions_content';
// @deprecated
export const MATCH_CONTENT_TYPE_FORM_TITLE = 'form_title';
// @deprecated
export const MATCH_CONTENT_TYPE_FORM_CONTENT = 'form_content';
export const MATCH_CONTENT_TYPE_HOME_FORM_TITLE = 'home_form_title';
export const MATCH_CONTENT_TYPE_HOME_FORM_CONTENT = 'home_form_content';
export const MATCH_CONTENT_TYPE_AWAY_FORM_TITLE = 'away_form_title';
export const MATCH_CONTENT_TYPE_AWAY_FORM_CONTENT = 'away_form_content';
export const MATCH_CONTENT_TYPE_H_2_H_TITLE = 'h2h_title';
export const MATCH_CONTENT_TYPE_H_2_H_CONTENT = 'h2h_content';
export const MATCH_CONTENT_TYPE_STANDINGS_TITLE = 'standings_title';
export const MATCH_CONTENT_TYPE_STANDINGS_CONTENT = 'standings_content';
