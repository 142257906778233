export const DOMAIN_MX = 'futbolhoyenvivo.mx';

export const GLOBAL_GOOGLE_ADS_ENABLED = false;
export const GLOBAL_GOOGLE_OPTIMIZE_ENABLED = false;

export const FOOTER_MATCH_COUNT = 20;
export const HOMEPAGE_DAYS_MORE = 5;
export const LEAGUE_DAYS_MORE = 7;

export const DATE_MX_DD_MM_YYYY = 'dd/MM/yyyy';
export const DATE_YYYY_MM_DD = 'yyyy-MM-dd';
export const DATE_d_LLL = 'd LLL';
export const DATE_d_LLLL = 'd LLLL';
export const DATE_HH_mm = 'HH:mm';
export const DATE_dd_MM = 'dd MMM';
export const DATE_PUBLISHED = 'dd MMM yyyy, HH:mm';

export const TV_URL = 'https://a.sporticos.com/es/go/MX_EN_VIVO_13_06_2024?bt=aHR0cHM6Ly93d3cuYmV0MzY1LmNvbS9vbHAvbGl2ZS1zdHJlYW1pbmc/YWZmaWxpYXRlPTM2NV8wMzI4Nzc0OQ==';

export const USE_FIXED_TIMEZONE = true;
export const TZ_MEXICO_CITY = 'America/Mexico_City';
export const TZ_PARIS = 'Europe/Paris';

export const AXIOS_TIMEOUT = 60000;
