import { addMinutes } from 'date-fns';
import { prepareAssetPath } from '~/utils/common';
import { TZ_MEXICO_CITY } from '~/config/global';
import { FEED_TYPE_LEAD } from '~/config/match';

export function getJsonLdOrganization() {
  return {
    '@type': 'Organization',
    name: 'futbolhoyenvivo.mx',
    url: 'https://futbolhoyenvivo.mx',
  };
}

export function getJsonLdOrganizationLogo() {
  return {
    '@type': 'ImageObject',
    url: 'https://futbolhoyenvivo.mx/PROVIDER_LOGO_HERE',
  };
}

export function getJsonLdTeam(team, baseUrl) {
  const logoPath = prepareAssetPath(team.logo);

  return {
    '@type': 'SportsTeam',
    sport: 'Football',
    name: team.name,
    logo: `${baseUrl}${logoPath}`,
  };
}

export function getJsonLdLocation(match) {
  const { venue } = match;

  if (!venue) {
    return {
      '@type': 'Place',
      name: `Estadio ${match.home.name}`,
    };
  }

  return {
    '@type': 'Place',
    name: venue.name,
  };
}

export function getJsonLdMatchDescription(match) {
  const parts = [];

  const { competition, season, round } = match;

  if (competition) {
    parts.push(competition.name);
  }

  if (season && season.year) {
    parts.push(season.year);
  }

  if (round && round.number) {
    parts.push(round.number);
  }

  if (parts.length === 0) {
    return null;
  }

  return parts.join(' ');
}

function generateMatchUrl(resolveRoute, page) {
  if (!page) {
    return null;
  }

  const { slug } = page;

  if (!slug) {
    return null;
  }

  return resolveRoute({
    name: 'match-slug',
    params: {
      slug,
    },
  });
}

function generateImageUrl(page, $i18n) {
  const { baseUrl, locale } = $i18n;

  if (!page) {
    return null;
  }

  const { slug } = page;

  if (!slug) {
    return null;
  }

  return {
    '@type': 'ImageObject',
    width: 1200,
    height: 675,
    url: `${baseUrl}/images/${locale}/match/${slug}?timezone=${TZ_MEXICO_CITY}`,
  };
}

function getJsonLdSportEvent(match, formatDate, $i18n, resolveRoute, withOffer) {
  const description = getJsonLdMatchDescription(match);

  const {
    home,
    away,
    start_time,
    page,
  } = match;

  const startDateRaw = new Date(start_time);
  const startDate = formatDate(startDateRaw);
  const endDate = formatDate(addMinutes(startDateRaw, 105));
  const name = `${home.name} vs ${away.name}`;
  const { baseUrl } = $i18n;

  const url = generateMatchUrl(resolveRoute, page);
  const image = generateImageUrl(page, $i18n);

  const broadcastOfEvent = {
    '@context': 'https://schema.org',
    '@type': 'SportsEvent',
    name,
    eventStatus: 'http://schema.org/EventScheduled',
    eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
    description,
    url,
    startDate,
    endDate,
    sport: 'Football',
    image,
    homeTeam: getJsonLdTeam(home, baseUrl),
    awayTeam: getJsonLdTeam(away, baseUrl),
    location: getJsonLdLocation(match),
  };

  if (withOffer) {
    broadcastOfEvent.offers = {
      '@type': 'Offer',
      availability: 'http://schema.org/OnlineOnly',
      price: 0.00,
      priceCurrency: 'EUR',
      validFrom: startDate,
      startDate,
      endDate,
      url,
    };
  }
  return broadcastOfEvent;
}

export function getJsonLdBroadcastEvent(match, { formatDate, resolveRoute, withOffer, $i18n }) {
  const description = getJsonLdMatchDescription(match);
  const {
    home,
    away,
    start_time,
  } = match;
  const startDateRaw = new Date(start_time);
  const startDate = formatDate(startDateRaw);
  const endDate = formatDate(addMinutes(startDateRaw, 105));
  const name = `${home.name} vs ${away.name}`;
  const broadcastOfEvent = getJsonLdSportEvent(match, formatDate, $i18n, resolveRoute, withOffer);

  return {
    '@context': 'https://schema.org',
    '@type': 'BroadcastEvent',
    name,
    description,
    startDate,
    endDate,
    isLiveBroadcast: true,
    videoFormat: 'HD',
    broadcastOfEvent,
  };
}
export function getJsonLdNewsArticle({ match, feeds, content }, { formatDate, resolveRoute, withOffer, $i18n }) {
  const { page } = match;
  const url = generateMatchUrl(resolveRoute, page);
  const imageUrl = generateImageUrl(page, $i18n);
  const about = getJsonLdSportEvent(match, formatDate, $i18n, resolveRoute, withOffer);
  const organization = getJsonLdOrganization();
  const feedLead = feeds.find(({ type }) => type === FEED_TYPE_LEAD);
  const h1 = feedLead.heading;

  return {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    headline: h1,
    datePublished: content.created_at,
    dateModified: content.modified_at,
    name: h1,
    description: feedLead.lead, // meta description
    articleBody: feedLead.content, // preview content
    articleSection: 'Soccer',
    url,
    thumbnailUrl: imageUrl,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    author: organization,
    creator: organization,
    publisher: {
      ...organization,
      logo: getJsonLdOrganizationLogo(),
    },
    image: {
      '@type': 'ImageObject',
      width: 1200,
      height: 675,
      url: imageUrl,
    },
    about,
  };
}
